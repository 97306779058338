/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetCaseHistoryListQuery } from 'store/api'
import { useCaseId } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CaseFollowUpHistoryCard from './CaseFollowUpComponents/CaseFollowUpHistoryCard'
import CaseWorkflowQuoteCard from './CaseFollowUpCards/CaseFollowUpQuoteCard'
import CaseFollowUpPaiementJalonCard from './CaseFollowUpCards/CaseFollowUpPaiement'
import CaseWorkflowMeasureJalonCard from './CaseFollowUpCards/CaseFollowUpPostMeasureJalonCard'
import CaseWorkflowWorkEndJalonCard from './CaseFollowUpCards/CaseFollowUpWorkDoneJalonCard'
import CaseWorkflowInvoiceCard from './CaseFollowUpCards/CaseFollowUpInvoiceCard'
import CaseWorkflowDot from './CaseFollowUpComponents/CaseFollowUpDot'
import CaseFollowUpMissionJalonCard from './CaseFollowUpCards/CaseFollowUpMissionJalonCard'

/* Type imports ------------------------------------------------------------- */
import {
  WorkflowEtat,
  WorkflowJalon,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface LineContainerProps {
  last: boolean;
}

const LineContainer = styled.div<LineContainerProps>`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => props.theme.colors.grey};
  margin-left: 10px;
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '30px'};
  .MuiPaper-root {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseFollowUpPageProps {}

const CaseFollowUpPage: React.FC<CaseFollowUpPageProps> = () => {
  const caseId = useCaseId()

  const {
    currentData: caseFollowUp,
    isFetching: isFetchingCaseFollowUp,
  } = useGetCaseHistoryListQuery(caseId)

  const findJalon = (jalon: WorkflowJalon) => {
    if (!caseFollowUp) return
    switch (jalon) {
      case WorkflowJalon.Missionnement:
        return (
          {
            jalon: caseFollowUp.jalonMissionnement,
            render: (
              <CaseFollowUpMissionJalonCard jalon={caseFollowUp.jalonMissionnement} />
            ),
          }
        )
      case WorkflowJalon.Contact:
        return (
          {
            jalon: caseFollowUp.jalonPriseDeContact,
            render: (
              <CaseFollowUpHistoryCard
                jalon={
                  { ...caseFollowUp.jalonPriseDeContact, libelle: 'Prise de contact',
                    date: caseFollowUp.jalonPriseDeContact.dateValidation || '' }
                }
              />
            ),
          }
        )
      case WorkflowJalon.RDVMetre:
        if (!caseFollowUp.jalonRDVMetre) return
        return (
          {
            jalon: caseFollowUp.jalonRDVMetre,
            render: (
              <CaseFollowUpHistoryCard
                jalon={{ ...caseFollowUp.jalonRDVMetre, libelle: 'RDV métré', date: caseFollowUp.jalonRDVMetre.dateRendezVous || '' }}
              />
            ),
          }
        )
      case WorkflowJalon.PostRdvMetre:
        if (!caseFollowUp.jalonPostRdvMetre) return
        return (
          {
            jalon: caseFollowUp.jalonPostRdvMetre,
            render: (
              <CaseWorkflowMeasureJalonCard jalon={caseFollowUp.jalonPostRdvMetre} />
            ),
          }
        )
      case WorkflowJalon.Devis:
        if (!caseFollowUp.jalonDevis) return
        return (
          {
            jalon: caseFollowUp.jalonDevis,
            render: (
              <CaseWorkflowQuoteCard
                jalonDevis={caseFollowUp.jalonDevis}
                caseId={caseId}
              />
            ),
          }
        )
      case WorkflowJalon.Paiement:
        if (!caseFollowUp.jalonPaiement) return
        return ( {
          jalon: caseFollowUp.jalonPaiement,
          render: (
            <CaseFollowUpPaiementJalonCard
              jalon={caseFollowUp.jalonPaiement}
            />
          ),
        }
        )
      case WorkflowJalon.RDVTravaux:
        return (
          {
            jalon: caseFollowUp.jalonRDVTravaux,
            render: (
              <CaseFollowUpHistoryCard
                jalon={
                  { ...caseFollowUp.jalonRDVTravaux, libelle: 'RDV travaux',
                    date: caseFollowUp.jalonRDVTravaux.rendezVousTravaux[0]?.dateDebut || '' }
                }
              />
            ),
          }
        )
      case WorkflowJalon.FinDeTravaux:
        if (!caseFollowUp.jalonFinDeTravaux) return
        return (
          {
            jalon: caseFollowUp.jalonFinDeTravaux,
            render: (
              <CaseWorkflowWorkEndJalonCard jalon={caseFollowUp.jalonFinDeTravaux} />
            ),
          }
        )
      case WorkflowJalon.Facture:
        if (!caseFollowUp.jalonFacture) return
        return (
          {
            jalon: caseFollowUp.jalonFacture,
            render: (
              <CaseWorkflowInvoiceCard jalonFacture={caseFollowUp.jalonFacture} />
            ),
          }
        )
      case WorkflowJalon.DossierTermine:
        return (
          {
            jalon: caseFollowUp.jalonDossierTermine,
            render: (
              <CaseFollowUpHistoryCard
                jalon={
                  { ...caseFollowUp.jalonDossierTermine, libelle: caseFollowUp.jalonDossierTermine.type.libelle ?? 'Dossier terminé',
                    date: caseFollowUp.jalonDossierTermine.date || '' }
                }
                notes={caseFollowUp?.jalonDossierTermine.expAssure}
                ratingComment={caseFollowUp?.jalonDossierTermine.assureCommentaire}
              />
            ),
          }
        )
      default:
        break
    }
  }
  return (
    <div>
      <LargeTitle>
        Suivi Dossier
      </LargeTitle>
      {
        (isFetchingCaseFollowUp || !caseFollowUp) ?
          <CircularProgress /> :
          <>
            {
              caseFollowUp.order.map((caseHistory, index) => (
                <LineContainer
                  key={caseHistory}
                  last={index === caseFollowUp.order.length - 1}
                >
                  <CaseWorkflowDot state={findJalon(caseHistory)?.jalon?.etat || WorkflowEtat.NonFait} />
                  {findJalon(caseHistory)?.render}
                </LineContainer>
              ))
            }
          </>
      }
      <Footer />
    </div>
  )
}

export default CaseFollowUpPage
