/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type imports ------------------------------------------------------------- */
import {
  EtatDevis,
  EtatFacture,
  WorkflowEtat,
  WorkflowJalon,
  EtatMissionnement,
} from 'API/__generated__/Api'
import type { ChipColor } from 'types/ChipColor'

/* Type declarations -------------------------------------------------------- */
type Jalon = WorkflowJalon |
'sousJalonPhotoApresTravaux' |
'sousJalonAttestationFinDeTravaux' |
'sousJalonPhotoAvantTravaux' |
'sousJalonAttestationTva'

/* Styled components -------------------------------------------------------- */
const Chip = styled(ColoredSquareChip)`
  width: 100%;
  height: 100%;
  min-height: 37px;
  white-space: pre-line;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowJalonTextProps {
  jalon: Jalon;
  state: WorkflowEtat;
  date: string;
  quoteState?: EtatDevis;
  invoiceState?: EtatFacture;
  missionState?: EtatMissionnement;
  comment?: string;
}

const CaseWorkflowJalonText: React.FC<CaseWorkflowJalonTextProps> = ({
  jalon,
  state,
  date,
  quoteState,
  invoiceState,
  missionState,
  comment,
}) => {

  const displayDate = () => {
    if (!date) return ''
    return ` le ${DateUtils.APIStrToLocalDateString(date)}`
  }

  const getText = () => {
    switch (jalon) {
      case WorkflowJalon.Missionnement:
        if (missionState === EtatMissionnement.EnAttente) {
          return `Reçu le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        } else if (missionState === EtatMissionnement.Accepte) {
          return `Accepté${displayDate()}`
        } else if (missionState === EtatMissionnement.Refuse) {
          return `Refusé${displayDate()}`
        } else if (missionState === EtatMissionnement.Annule) {
          return `Annulé${displayDate()}`
        }
        break
      case WorkflowJalon.Contact:
        if (state === WorkflowEtat.EnAttente) {
          return 'En attente de prise de contact'
        } else if (state === WorkflowEtat.Fait) {
          return `Aboutie${displayDate()}`
        } else if (state === WorkflowEtat.NonAboutie || state === WorkflowEtat.Annule) {
          return `Non aboutie${displayDate()}`
        }
        break
      case WorkflowJalon.RDVMetre:
        if (state === WorkflowEtat.EnAttente && !date) {
          return 'En attente de planification'
        } else if (state === WorkflowEtat.EnAttente) {
          return `Planifié le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        } else if (state === WorkflowEtat.Fait) {
          return `Effectué le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        }
        break
      case WorkflowJalon.PostRdvMetre:
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validé le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        }
        break
      case 'sousJalonPhotoAvantTravaux':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case WorkflowJalon.Devis:
        if (quoteState) {
          if (quoteState === EtatDevis.Brouillon) {
            return `En brouillon${displayDate()}`
          } else if (quoteState === EtatDevis.Valide) {
            return `Validé${displayDate()}${comment}`
          } else if (quoteState === EtatDevis.Refuse) {
            return `Refusé${displayDate()}${comment || ''}`
          } else if (quoteState === EtatDevis.Transmis) {
            return `Reçu${displayDate()}${comment || ''}`
          } else if (quoteState === EtatDevis.Retransmis) {
            return `Reçu${displayDate()}${comment || ''}`
          }
        }
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Brouillon) {
          return `En brouillon${displayDate()}`
        } else if (state === WorkflowEtat.Annule) {
          return `Refusé${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validé${displayDate()}`
        }
        break
      case WorkflowJalon.Paiement:
        if (state === WorkflowEtat.EnAttente) {
          return 'En attente de paiement'
        } else if (state === WorkflowEtat.Fait) {
          return `Paiement effectué${displayDate()}`
        } else if (state === WorkflowEtat.PartiellementPaye) {
          return `Partiellement payé${displayDate()}`
        }
        break
      case WorkflowJalon.RDVTravaux:
        if (state === WorkflowEtat.EnAttente && !date) {
          return 'En attente de planification'
        } else if (state === WorkflowEtat.EnAttente) {
          return `Prévu${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Effectué${displayDate()}`
        }
        break
      case WorkflowJalon.FinDeTravaux:
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validé${displayDate()}`
        }
        break
      case 'sousJalonPhotoApresTravaux':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case 'sousJalonAttestationFinDeTravaux':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case 'sousJalonAttestationTva':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case WorkflowJalon.Facture:
        if (invoiceState) {
          if (invoiceState === EtatFacture.Valide) {
            return `Validée${displayDate()}${comment}`
          } else if (invoiceState === EtatFacture.Refuse) {
            return `Refusée${displayDate()}${comment}`
          } else if (invoiceState === EtatFacture.Envoye) {
            return `Reçue${displayDate()}${comment}`
          } else if (invoiceState === EtatFacture.Partiel) {
            return `Réglée partiellement${displayDate()}${comment}`
          } else if (invoiceState === EtatFacture.Regle) {
            return `Réglée${displayDate()}${comment}`
          }
        }
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}${comment}`
        } else if (state === WorkflowEtat.Brouillon) {
          return `En brouillon${displayDate()}${comment}`
        } else if (state === WorkflowEtat.Annule) {
          return `Refusée${displayDate()}${comment}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validée${displayDate()}${comment}`
        }
        break
      case WorkflowJalon.DossierTermine:
        if (state === WorkflowEtat.Fait) {
          return `Cloturé${displayDate()}`
        }
        break
      default:
        break
    }
  }

  const getColor = (): ChipColor => {
    switch (state) {
      case WorkflowEtat.Annule:
      case WorkflowEtat.NonAboutie:
        return 'red'
      case WorkflowEtat.Fait:
        return 'green'
      case WorkflowEtat.PartiellementPaye:
        return 'orange'
      default:
        return 'grey'
    }
  }

  if (state === WorkflowEtat.NonFait) return <div />

  return (
    <Chip color={getColor()}>
      {getText()}
    </Chip>
  )
}

export default CaseWorkflowJalonText
